<template>
  <v-container style="margin-left: 60px;">
  <v-row class="flex-row align-self-stretch">
    <v-col cols="2">
      <v-row>
        <v-col>
          <p class="title">Aperçu global</p>
          <p class="subtitle">Options</p>
        <v-switch dense class="d-inline"
            v-model="normalized"
            @change="compare()"
            :label="`Valeur ${normalized ? 'normalisées' : 'brutes'}`">
          </v-switch>
          <v-progress-linear v-show="loading"
            indeterminate
            color="primary">
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-sheet>
        <p class="subtitle">Entretiens</p>
        <v-text-field v-model="search" label="Filtrer par entretien ou patient..."
        light flat hide-details clearable clear-icon="mdi-filter-remove">
      </v-text-field>
    </v-sheet>
    <v-treeview hoverable open-on-click v-model="selection"
      :items="items" :search="search" dense  selection-type="leaf"
      selectable return-object>
      <template v-slot:prepend="{ item }">
        <v-icon>{{ item.children ? 'mdi-account-circle' : 'mdi-message-text' }}</v-icon>
      </template>
    </v-treeview>
    <v-btn outlined block @click="compare()" v-show="selection.length > 1"
    color="indigo" class="mt-5">Comparer</v-btn>
  </v-col>

  <v-col cols="10" class="align-center">
    <v-row v-if="analysis" class="d-flex align-self-stretch">
      <v-col cols="4" v-for="(item, i) in analysis" :key="i">
        <v-card elevation="1" v-if="selection[i]">
          <v-card-title>{{ selection[i].name }}
            </v-card-title>
          <v-card-text>
            <v-expansion-panels flat accordion multiple v-for="(ana, j) in
                item.analysis" :key="j">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Participant {{ ana.participant }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list two-line dense>
                        <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Données globales</v-list-item-title>
                          <v-list-item-subtitle>
                            Nombre de mots moyen/TDP: {{ item.general.average }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      </v-list>
                      <SingleAnalysis :analysis="ana" :normalized="normalized ? 'yes' : 'no'"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-show="analysis.length === 0">
        <div
        class="mx-auto justify-center d-block">
          <v-img class="mx-auto mb-5" src="../../assets/transverse.svg" max-width="500"></v-img>
          <p class="text-center display-1 font-weight-thin grey--text text--lighten-1">
              Pas d'entretien sélectionné
          </p>
        </div>
      </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>

import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';
import SingleAnalysis from '@/components/common/SingleAnalysis.vue';

export default {
  name: 'TransversalAnalysis',
  mixins: [MixinInterviews, MixinPatients],
  components: { SingleAnalysis },
  async mounted() {
    const patients = await this.getPatientList();
    let index = 1;

    patients.forEach(async (patient) => {
      index += 1;
      const treeElement = { id: index, name: patient.name, children: [] };

      // eslint-disable-next-line no-underscore-dangle
      const interviews = await this.getInterviewsList(patient._id);

      if (interviews.length) {
        interviews.forEach((interview) => {
          index += 1;
          treeElement.children.push({
            id: index,
            name: interview.name,
            // eslint-disable-next-line no-underscore-dangle
            interviewId: interview._id,
            // eslint-disable-next-line no-underscore-dangle
            patientId: patient._id,
          });
        });
        this.items.push(treeElement);
      }
    });
  },

  methods: {
    async compare() {
      this.loading = true;
      const interviewsList = this.selection.map(
        (x) => ({ patientId: x.patientId, interviewId: x.interviewId }),
      );
      this.analysis = await this.getMultiAnalysis(
        this.normalized,
        interviewsList,
        ['syntax-basics', 'raw-basics', 'syntax-advanced', 'deictics', 'disfluences'],
      );

      this.loading = false;
    },
  },
  data: () => ({
    selection: [],
    normalized: false,
    patients: null,
    search: '',
    interviews: null,
    items: [],
    analysis: [],
    loading: false,
  }),
};
</script>
